<template>
  <v-row>
    <v-col class="col-lg-4 col-md-12">
      <v-card class="mb-7">
        <v-toolbar flat>
          <v-toolbar-title>My profile</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-text-field
            outlined
            label="First name"
            v-model="profile.first_name"
            data-testid="first-name"
          ></v-text-field>
          <v-text-field
            outlined
            label="Last name"
            v-model="profile.last_name"
            data-testid="last-name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            block
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-snackbar
      v-model="snackbar.show"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn color="pink" @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      snackbar: {
        show: false,
        y: "top",
        x: null,
        mode: "",
        timeout: 3000,
        text: "Profile succesfully saved.",
        color: "success",
      },
      profile: {
        first_name: "",
        last_name: "",
      },
    }
  },
  created: function () {
    this.getUserProfileData()
  },
  methods: {
    getUserProfileData() {
      let app = this
      app.$store
        .dispatch("userProfileGetData")
        .then(function (response) {
          app.profile = response.data.data.user
        })
        .catch(({ response }) => {
          //app.handleValidationErrors(response);
          // console.log('Error on USER_PROFILE_GET_DATA', response);
        })
    },
    save() {
      this.editUserProfile(this.profile, true)
    },
    editUserProfile(data, save) {
      let app = this
      // app.$validator.errors.clear('form-user');
      let saveData = save || false
      if (saveData) {
        app.$store
          .dispatch("userProfileEdit", data)
          .then(() => {
            app.snackbar.show = true
          })
          .catch(({ response }) => {
            //console.log('error on USER_PROFILE_EDIT');
          })
      }
    },
  },
}
</script>
